function Contact() {
    return (
      <section className="text-gray-600 body-font dark:text-white">
        <div className="max-w-sm mx-auto flex px-4 py-8 items-center justify-center flex-col gap-4">
          <a
            className="w-full"
            href="https://www.linkedin.com/in/roberthaehle/"
          >
            <button className="w-full rounded-lg outline outline-1 dark:outline-slate-800 px-8 py-4">
              LinkedIn
            </button>
          </a>
          <a className="w-full" href="mailto:moin@roberthaehe.de">
            <button className="w-full rounded-lg outline outline-1 dark:outline-slate-800 px-8 py-4">
              Email
            </button>
          </a>
        </div>
      </section>
    );
}

export default Contact;
