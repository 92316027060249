import Robert from '../img/robert.png';

function Profile() {
  return (
    <section className="text-gray-600 body-font dark:text-white bg-slate-100 dark:bg-slate-950">
      <div className="mx-auto flex px-4 py-8 items-center justify-center flex-col gap-4">
        <img className="rounded-full outline outline-1 dark:outline-slate-800 w-24 h-24" src={Robert} alt="" />
        <div className="text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 dark:text-white">
            Robert Hähle
          </h1>
          <p className="mb-8 leading-relaxed">Full Stack Web Developer</p>
        </div>
      </div>
    </section>
  );
}

export default Profile;
