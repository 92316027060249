import React from "react";
import Profile from './components/Profile';
import Contact from './components/Contact';

function App() {

  return (
    <div className="App bg-slate-100 dark:bg-slate-950 min-h-screen flex justify-center align-middle flex-col">
      <Profile />
      <Contact />
      {/* You can add a Footer component here if you have one */}
    </div>
  );
}

export default App;
